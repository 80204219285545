import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import PrimaryLinkButton from "../components/PrimaryLinkButton"
import { GatsbyImage } from "gatsby-plugin-image"
import GetStarted from "../components/GetStarted"
import RelatedProjects from "../components/RelatedProjects"
import ProjectHeader from "../components/ProjectHeader"
import ProjectKeyTag from "../components/ProjectKeyTag"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      fileAbsolutePath
      frontmatter {
        title
        slug
        url
        industry
        features
        technologies
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        featuredImageMobile {
          childImageSharp {
            gatsbyImageData(width: 768, layout: CONSTRAINED)
          }
        }
      }
      body
    }
  }
`

function ProjectTemplate({ data: { mdx: project }, scope, pageContext }) {
  if (!project) {
    return <p>No Project Found? This should be a 404</p>
  }

  return (
    <>
      <Seo title={project.frontmatter.title} description={project.excerpt} />
      <ProjectHeader
        title={project.frontmatter.title}
        excerpt={project.excerpt}
      >
        {project.frontmatter.url && (
          <div className="pt-6">
            <PrimaryLinkButton
              title="view website"
              to={project.frontmatter.url}
              external
            />
          </div>
        )}
      </ProjectHeader>
      <div className="bg-gray-100">
        <div className="container">
          <div className="py-8 lg:py-10">
            <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8 lg:space-x-10">
              <ProjectKeyTag
                label="Industry:"
                tags={project.frontmatter.industry}
              />
              <ProjectKeyTag
                label="Key Features:"
                tags={project.frontmatter.features}
              />
              <ProjectKeyTag
                label="Technologies:"
                tags={project.frontmatter.technologies}
              />
            </div>
          </div>
        </div>
        <div className="hidden md:block relative">
          <div className="absolute left-0 bottom-0 w-full h-24 bg-white" />
          <div className="container relative">
            <div className="overflow-hidden shadow">
              <GatsbyImage
                image={
                  project.frontmatter.featuredImage.childImageSharp
                    .gatsbyImageData
                }
                className="w-full h-auto"
                alt={project.frontmatter.title}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <GatsbyImage
          image={
            project.frontmatter.featuredImageMobile.childImageSharp
              .gatsbyImageData
          }
          className="w-full h-auto"
          alt={project.frontmatter.title}
        />
      </div>
      <div className="container">
        <div className="max-w-screen-lg pt-10">
          <div className="prose max-w-full">
            <MDXRenderer
              scope={{
                ...scope,
              }}
            >
              {project.body}
            </MDXRenderer>
          </div>
        </div>
        {project.frontmatter.url && (
          <div className="pt-6 pb-16">
            <PrimaryLinkButton
              title="view website"
              to={project.frontmatter.url}
              external
            />
          </div>
        )}
      </div>
      <RelatedProjects currentProject={project} />
      <GetStarted />
    </>
  )
}

export default ProjectTemplate
