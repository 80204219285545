import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { includes, orderBy } from "lodash"
import ProjectCard from "./ProjectCard"

class SimilarProjectsFactory {
  constructor(projects, currentProjectSlug) {
    this.projects = projects.filter(
      aProject => aProject.node.frontmatter.slug !== currentProjectSlug
    )
    this.currentProjectSlug = currentProjectSlug
    this.maxProjects = 3
    this.industry = []
    this.features = []
    this.technologies = []
  }

  setMaxProjects(m) {
    this.maxProjects = m
    return this
  }

  setIndustry(industryArray) {
    this.industry = industryArray
    return this
  }

  setFeatures(featureArray) {
    this.features = featureArray
    return this
  }

  setTechnologies(technologyArray) {
    this.technologies = technologyArray
    return this
  }

  getProjects() {
    const { industry, features, technologies, projects, maxProjects } = this
    const identityMap = {}

    function getSlug(project) {
      return project.node.frontmatter.slug
    }

    function addToMap(project) {
      const slug = getSlug(project)

      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          project: project,
          points: 0,
        }
      }
    }

    function addIndustryPoints(project, industry) {
      const industryPoints = 3
      const slug = getSlug(project)

      if (project.node.frontmatter.industry) {
        project.node.frontmatter.industry.forEach(aIndustry => {
          if (includes(industry, aIndustry)) {
            identityMap[slug].points += industryPoints
          }
        })
      }
    }

    function addFeaturePoints(project, features) {
      const featurePoints = 2
      const slug = getSlug(project)

      if (project.node.frontmatter.features) {
        project.node.frontmatter.features.forEach(aFeature => {
          if (includes(features, aFeature)) {
            identityMap[slug].points += featurePoints
          }
        })
      }
    }

    function addTechnologyPoints(project, technologies) {
      const technologyPoint = 1
      const slug = getSlug(project)

      if (project.node.frontmatter.technologies) {
        project.node.frontmatter.technologies.forEach(aTechnology => {
          if (includes(technologies, aTechnology)) {
            identityMap[slug].points += technologyPoint
          }
        })
      }
    }

    function getIdentityMapAsArray() {
      return Object.keys(identityMap).map(slug => identityMap[slug])
    }

    for (let project of projects) {
      addToMap(project)
      addIndustryPoints(project, industry || [])
      addFeaturePoints(project, features || [])
      addTechnologyPoints(project, technologies || [])
    }

    const arrayIdentityMap = getIdentityMapAsArray()
    const similarProjects = orderBy(arrayIdentityMap, ["points"], ["desc"])
    return similarProjects.splice(0, maxProjects).map(p => p.project)
  }
}

function RelatedProjects({ currentProject }) {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fields: { collection: { eq: "projects" } } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 1000
      ) {
        edges {
          node {
            excerpt
            frontmatter {
              title
              slug
              industry
              features
              technologies
              logo {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `)

  const relatedProjects = new SimilarProjectsFactory(
    data.allMdx.edges,
    currentProject.frontmatter.slug
  )
    .setMaxProjects(3)
    .setFeatures(currentProject.frontmatter.features)
    .setIndustry(currentProject.frontmatter.industry)
    .setTechnologies(currentProject.frontmatter.technologies)
    .getProjects()

  return (
    <div className="bg-gray-100 py-8">
      <div className="container">
        <div>
          <h3 className="text-2xl md:text-4xl text-gray-900 font-bold">
            Other Projects
          </h3>
        </div>
        <div className="pt-5 pb-5 grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-5 md:gap-y-0">
          {relatedProjects.map(({ node: project }) => {
            return (
              <ProjectCard key={project.frontmatter.slug} project={project} />
            )
          })}
        </div>
      </div>
    </div>
  )
}


export default RelatedProjects
